

























import Vue from "vue";
import { mapGetters } from "vuex";
import { HeadData } from "@/interfaces/recruiter/jobs/job_listing/listing_thead";
import CandidateInterviewListingBody from "@/components/candidate/interviews/CandidateInterviewListingBody.vue";
import CandidateInterviewListingHeader from "@/components/candidate/interviews/CandidateInterviewListingHeader.vue";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "CandidateInterviewsListing",
  components: {
    CandidateInterviewListingHeader,
    CandidateInterviewListingBody
  },
  data() {
    return {
      table_headers: [] as HeadData[]
    };
  },
  mounted() {
    this.intializeTableHeaders();
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  watch: {
    get_site_direction() {
      this.intializeTableHeaders();
    }
  },
  methods: {
    intializeTableHeaders() {
      this.table_headers = [
        {
          title: this.$t("recruiter.interview.listing.date-col")
        },
        {
          title: this.$t("recruiter.interview.listing.job-col")
        },
        {
          title: this.$t("recruiter.interview.listing.status-col")
        },
        {
          title: this.$t("recruiter.interview.listing.actions-col")
        }
      ];
    }
  }
});
