












import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { INTERVIEW_SEARCHED_JOB } from "@/store/modules/recruiter/constants";
import {
  CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING,
  GET_JOBS_LOADING,
  SEARCH_ANY_JOB
} from "@/store/modules/candidates/constants";
import AutoCompleteCandidateJobSearch from "@/components/shared/jobs/AutoCompleteCandidateJobSearch.vue";
import { SearchJobObject } from "@/interfaces/shared/jobs/auto_complete_job_search";

export default Vue.extend({
  name: "CandidateInterviewListingHeader",
  components: { AutoCompleteCandidateJobSearch },
  computed: {
    ...mapGetters("candidate", {
      jobs_loading: GET_JOBS_LOADING
    }),
    ...mapGetters("recruiter", {
      get_searched_job: INTERVIEW_SEARCHED_JOB
    })
  },
  methods: {
    ...mapMutations("recruiter", {
      set_searched_job: INTERVIEW_SEARCHED_JOB
    }),
    ...mapMutations("candidate", {
      set_search_job_loading: CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING
    }),
    ...mapActions("candidate", {
      search_any_job: SEARCH_ANY_JOB
    }),
    async search_job_title(value: SearchJobObject | null) {
      value
        ? this.set_search_job_loading(true)
        : this.set_search_job_loading(false);
      this.set_searched_job(value);
    }
  }
});
